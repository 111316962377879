<script setup lang="ts">
import { ref } from 'vue';
import {RiftAvatar, RiftButton, useAuth} from "~/shared";
const menu = ref([
  { title: 'Public profile', link: '', icon: 'user' },
  // { title: 'Branding', link: 'branding' },
  { title: 'My Link', link: '-links', icon: 'links' },
  // { title: 'Phone Number(s)', link: 'phone' },
]);
const localePath = useLocalePath();
const { profile } = useAuth();



</script>


<template>
  <div>
    <div :class="$style['header-setting']">
      <div :class="$style['profile']">
        <RiftAvatar
          :image-src="profile?.image"
          :name="profile?.name"
          :size="48"
        />
        <div>
          <span :class="$style['profile-name']">{{ profile?.name }}</span>
          <p :class="$style['profile-text']">
            Your personal account
          </p>
        </div>
      </div>

      <nuxt-link
        :class="$style['profile-page-link']"
        :to="`/profile/${profile?.id}`"
      >
        Go to your personal profile
      </nuxt-link>
    </div>
    <div :class="$style['layout']">
      <div :class="$style['menu']">
        <ul>
          <li
            v-for="item in menu"
            :key="item.title"
          >
            <nuxt-link
              :class="$style['menu-item']"
              :to="localePath(`profile-setting${item.link}`)"
              exact
            >
              <component :is="`svgo-${item.icon}`" />
              <span>  {{ item.title }}</span>
            </nuxt-link>
          </li>
        </ul>
      </div>

      <div :class="$style['content']">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" module>
.layout {
  display: flex;
  flex-wrap: wrap;
}

.header-setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.menu {
  width: 272px;
  padding: 10px;

}



li {
  margin-bottom: 1px;
}

.content {
  flex-grow: 1;

}



.profile {
  display: flex;
  gap: 5px;

}

.profile-name {
  font-size: 20px;
  font-weight: 600;
}
.profile-text {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-grey);
}


.profile-page-link {
  font-size: 12px;
  box-shadow: 0 0 0 1px var(--color-grey1);
  padding: 3px 12px;
  border-radius: 6px;
  transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);

  &:hover {
    opacity: .6;
  }
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-block: 0.375rem;

  padding-inline: 0.375rem;
  transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
  border-radius: 10px;
  transition-property: color, background-color, box-shadow, border-color;
  &:hover {
    background: rgba(208, 215, 222, 0.24);
  }
}



</style>

<style scoped>
.router-link-exact-active  {
  background: rgba(208, 215, 222, 0.24);

  &::after {
    background: rgba(208, 215, 222, 0.24);
    background: var(--color-blue-normal);
    border-radius: 0.4rem;
    content: "";
    height: 1.5rem;
    left: 15px;
    position: absolute;

    width: 0.25rem;
  }
}
</style>
